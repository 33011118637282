console.log(' ');(function($, window, document, undefined) {
	'use strict';

	/** @type {Object} Default options */
	var defaults = {
		debug: false,
		name: 'App',
	};

	/** @type {Object} Store with useful data */
	var store = {
		view: new Viewport(),
	};

	/** @type {Object} Test for stuff */
	var is = {
		get mobile() {
			return store.view.width < 768;
		},
		get tablet() {
			return store.view.width >= 768 && store.view.width <= 1024;
		},
		get desktop() {
			return store.view.width > 1024;
		},
		get mac() {
			return (
				navigator.platform === 'MacIntel' || navigator.platform === 'MacPPC'
			);
		},
	};

	/**
	 * App constructor
	 *
	 * @return {Object} The app's instance
	 */
	function App(options) {
		if (!(this instanceof App)) return new App(options);
		var self = this;

		// Mix instance and defaults options
		self.options = $.extend(true, {}, defaults, options);

		// Set properties
		self.store = store;
		self.is = is;

		// Debug helper
		self.store.hasDebug = self.options.debug;
		self.logger = logger(self.store.hasDebug, self.options.name + ':');

		// Generic DOM elements
		self.$html = $('html');

		if ('TweenLite' in window && 'Expo' in window) {
			TweenLite.defaultEase = Expo.easeOut;
		}

		// Update classes on resizend event
		self.store.view.onResizeend.setIsClasses = self.setIsClasses.bind(self);

		self.logger.log('constructor');
		self.setIsClasses();

        self.lazy = new Blazy ({
            selector: '.b-lazy',

            success: function(ele) {
                console.log('Images loaded');
            },
            error: function(ele) {
                console.log('Images not loaded');
            }
        });

		setTimeout(function() {
			self.lazy.revalidate();
		}, 100)

		return self;
	}

	// Set App prototypes
	App.prototype = $.extend(true, App.prototype, {
		setIsClasses: setIsClasses,
	});

	/**
	 * Set `is` classes on html
	 */
	function setIsClasses(view) {
		this.logger.log('setIsClasses');

		for (var key in this.is) {
			var method = this.is[key] ? 'addClass' : 'removeClass';
			this.$html[method]('is-' + key);
		}
	}

	/* ================================
	 * Init app globally
	 * ================================ */

	window.APP = new App({
		debug: 'HAS_DEBUG' in window && window.HAS_DEBUG,
		name: 'WMP'
	});

})(jQuery, window, document);
